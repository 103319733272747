import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { org.example.zone360.pages.HomePage() }
        ctx.router.register("/coach-details") {
                org.example.zone360.pages.coachDetails.CoachDetails() }
        ctx.router.register("/home") { org.example.zone360.pages.dashboard.DashboardPage() }
        ctx.router.register("/login") { org.example.zone360.pages.authentication.LoginPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle(org.example.zone360.style.CategoryItemStyle)
        ctx.theme.registerStyle(org.example.zone360.style.CategoryItemStyleDark)
        ctx.theme.registerStyle(org.example.zone360.style.CheckboxStyle)
        ctx.theme.registerStyle(org.example.zone360.style.CheckboxStyleDark)
        ctx.theme.registerStyle(org.example.zone360.style.CheckboxStyleError)
        ctx.theme.registerStyle(org.example.zone360.style.RadioStyle)
        ctx.theme.registerStyle(org.example.zone360.style.RadioStyleDark)
        ctx.theme.registerStyle(org.example.zone360.style.DropdownStyleLight)
        ctx.theme.registerStyle(org.example.zone360.style.DropdownStyleDark)
        ctx.theme.registerStyle(org.example.zone360.style.InputStyleDefault)
        ctx.theme.registerStyle(org.example.zone360.style.InputStyleFocus)
        ctx.theme.registerStyle(org.example.zone360.style.InputStyleError)
        ctx.theme.registerStyle(org.example.zone360.style.BaseTextStyle)
        ctx.theme.registerKeyframes(org.example.zone360.style.Spin)
        ctx.theme.registerKeyframes(org.example.zone360.style.ShiftRight)
        org.example.zone360.theme.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Zone360"))
    renderComposable(rootElementId = "root") {
        org.example.zone360.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}

package org.example.zone360.models

import kotlinx.serialization.SerialName

    data class CoachSessionData(
        val audioPath: String="",
        val coachName: String="",
        val time : String = "",
        val date : String = "",
        val duration : String = "",
        val sessionName: String="",

    )


package org.example.zone360.pages.dashboard.chart

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.example.zone360.models.GetClubPerformanceData
import org.example.zone360.models.GetClubPerformanceResponse
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Canvas
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLCanvasElement

@Composable
fun LineChartPage(
    breakpoint: Breakpoint,
    data: GetClubPerformanceData,
    selectedLanguage: SelectedLanguage
) {
    var datasets by remember { mutableStateOf(emptyList<Pair<List<Int>, String>>()) }
    var isDataReady by remember { mutableStateOf(false) }
    val sitePalette = ColorMode.current.toSitePalette()

    // Static x-axis labels
    val xValues1 = listOf(
        "Week1",
        "Week2",
        "Week3",
        "Week4",
    )

    LaunchedEffect(data) {
        // Extract weekly performance data
        val weeks = listOf(
            data.week1,
            data.week2,
            data.week3,
            data.week4
        )

        // Localized category labels
        val categories = if (selectedLanguage == SelectedLanguage.English) {
            listOf("Fitness", "Mental", "Tactical", "Technical", "Goalkeeper")
        } else {
            listOf("Atletico", "Mentale", "Tattica", "Tecnica", "Portiere")
        }

        // Create datasets for each category
        val datasetsFinal = categories.map { category ->
            val values = weeks.map { week ->
                when (category.lowercase()) {
                    if (selectedLanguage == SelectedLanguage.English) "fitness" else "atletico" -> week.fitness
                    if (selectedLanguage == SelectedLanguage.English) "mental" else "mentale" -> week.mental
                    if (selectedLanguage == SelectedLanguage.English) "tactical" else "tattica" -> week.tactical
                    if (selectedLanguage == SelectedLanguage.English) "technical" else "tecnica" -> week.technical
                    if (selectedLanguage == SelectedLanguage.English) "goalkeeper" else "portiere" -> week.goalkeeper
                    else -> 0
                }
            }
            val color = when (category.lowercase()) {
                if (selectedLanguage == SelectedLanguage.English) "technical" else "tecnica" -> sitePalette.technicalCategoryBorder.hex
                if (selectedLanguage == SelectedLanguage.English) "fitness" else "atletico" -> sitePalette.fitnessCategoryBorder.hex
                if (selectedLanguage == SelectedLanguage.English) "tactical" else "tattica" -> sitePalette.tacticalCategoryBorder.hex
                if (selectedLanguage == SelectedLanguage.English) "mental" else "mentale" -> sitePalette.mentalCategoryBorder.hex
                if (selectedLanguage == SelectedLanguage.English) "goalkeeper" else "portiere" -> sitePalette.goalKeeperCategoryBorder.hex
                else -> "#000000"
            }
            values to color
        }

        datasets = datasetsFinal as List<Pair<List<Int>, String>>
        isDataReady = true
    }

//    if (isDataReady) {
        Canvas(
            Modifier.fillMaxWidth()
                .height(350.px)
                .id("lineChart")
                .toAttrs()
        ) {}

        LaunchedEffect(Unit) {
            val canvas = document.getElementById("lineChart") as? HTMLCanvasElement
            if (canvas != null) {
                // Prepare datasets for the chart
                val dataArrays = datasets.joinToString(",\n") { dataset ->
                    """
                    {
                        data: ${dataset.first}, 
                        borderColor: "${dataset.second}",
                        fill: false
                    }
                    """.trimIndent()
                }

                // Add chart rendering script
                val script = """
                    if (window.xValues1 === undefined) {
                        window.xValues1 = [${xValues1.joinToString(",") { "\"$it\"" }}];
                    }

                    new Chart("lineChart", {
                        type: "line",
                        data: {
                            labels: window.xValues1,
                            datasets: [
                                $dataArrays
                            ]
                        },
                        options: {
                            legend: { display: false },
                            scales: {
                                x: { grid: { display: false } },
                                y: { grid: { display: true }, beginAtZero: true }
                            },
                            responsive: false,
                            maintainAspectRatio: true
                        }
                    });
                """.trimIndent()

                val scriptElement = document.createElement("script")
                scriptElement.textContent = script
                scriptElement.setAttribute("id", "lineChartScript")
                val existingScript = document.getElementById("lineChartScript")
                if (existingScript == null) {
                    document.body?.appendChild(scriptElement)
                } else {
                    existingScript.replaceWith(scriptElement)
                }
            } else {
                console.error("Canvas element not found")
            }
        }
//    }
//    else {
//        Text("Loading chart data...")
//    }
}





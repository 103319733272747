package org.example.zone360.pages.coachDetails.coachSession

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaVideo
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.SearchBar
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.CoachSessionData
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Divider
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedData
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CoachesSession(breakpoint: Breakpoint, sitePalette: SitePalette){
    var searchText by remember { mutableStateOf("") }
    var sessionDataType by remember { mutableStateOf(SelectedData.Training) }
    val dummySessions = listOf(
        CoachSessionData(
            audioPath = "/audio/session1.mp3",
            coachName = "John Smith",
            time = "8:55 PM",
            date = "Today ",
            duration = "8:55",
            sessionName = "Alice"
        ),
        CoachSessionData(
            audioPath = "/audio/session2.mp3",
            coachName = "Jane Doe",
            time = "9:30 PM",
            date = "Yesterday ",
            duration = "30:00",
            sessionName = "Bob"
        ),
        CoachSessionData(
            audioPath = "/audio/session3.mp3",
            coachName = "Mike Johnson",
            time = "7:00 AM",
            date = "Today",
            duration = "60:00",
            sessionName = "Charlie"
        )
    )
    var selectedSession by remember { mutableStateOf(dummySessions.first()) }
    Box (modifier = Modifier
        .then(
            if ( window.screen.width > 765) Modifier.height(700.px)
            else Modifier.maxHeight(915.px)
        )
        .margin(topBottom = if (breakpoint <= Breakpoint.MD)10.px else 0.px)
        .width(
            if (breakpoint > Breakpoint.LG) 33.percent
            else if (breakpoint >= Breakpoint.LG) 39.percent
            else if (breakpoint >= Breakpoint.MD) 44.percent
            else 100.percent
        )
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .borderRadius(15.px)
        .padding(20.px)
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            SectionTitle("Sessions")
            Row(
                modifier = Modifier.fillMaxWidth()
                    .height(42.px)
                    .margin(topBottom = 10.px)
                    .borderRadius(6.px)
                    .backgroundColor(sitePalette.lightGrey.rgb)
                    .padding(leftRight = 5.px, topBottom = 4.px)
                    .border(
                        width = 1.px,
                        style = LineStyle.Solid,
                        color = Colors.LightGray
                    ),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box (modifier = Modifier.fillMaxWidth(50.percent)
                    .height(34.px)
                    .borderRadius(4.px)
                    .padding(leftRight = 12.px, topBottom = 6.px)
                    .backgroundColor(if (sessionDataType == SelectedData.Training) Palette.White.rgbLight else Colors.Transparent)
                    .onClick { sessionDataType = SelectedData.Training }
                    .boxShadow(
                        offsetX = 0.px,
                        offsetY = if(sessionDataType == SelectedData.Training)2.px else 0.px,
                        blurRadius = if(sessionDataType == SelectedData.Training)16.px else 0.px,
                        spreadRadius = null,
                        color = sitePalette.boxShadowColor.rgb),
                    contentAlignment = Alignment.Center){
                      FieldTitleTextNormalSimple(
                          text = "Training",
                          textAlign = TextAlign.Center,
                          fontWeight = 500,
                          fontSize = 16.px,
                      )
                }
                Box (modifier = Modifier.fillMaxWidth(50.percent)
                    .height(34.px)
                    .borderRadius(4.px)
                    .padding(leftRight = 12.px, topBottom = 6.px)
                    .backgroundColor(if (sessionDataType != SelectedData.Training) Palette.White.rgbLight else Colors.Transparent)
                    .onClick { sessionDataType = SelectedData.Matches }
                    .boxShadow(
                        offsetX = 0.px,
                        offsetY = if(sessionDataType == SelectedData.Matches)2.px else 0.px,
                        blurRadius = if(sessionDataType == SelectedData.Matches)16.px else 0.px,
                        spreadRadius = null,
                        color = sitePalette.boxShadowColor.rgb),
                    contentAlignment = Alignment.Center){
                    FieldTitleTextNormalSimple(
                        text = "Matches",
                        textAlign = TextAlign.Center,
                        fontWeight = 500,
                        fontSize = 16.px,
                    )
                }

            }
                SearchBar(
                    placeholder = "Search...",
                    id = "session_Searchbar",
                    breakpoint = Breakpoint.LG,
                    onEnterClick = {},
                    onChange = { newSearchText ->
                        searchText = newSearchText
                    },
                    onClick = {},
                    width = 100.percent,
                    showClearButton = false,
                    height = 48.px,
                    borderColor = Colors.Transparent
                )
            
            Column(modifier = Modifier.fillMaxWidth().margin(topBottom = 15.px)
                .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)) {
                dummySessions.forEach { session ->
                    CoachAssessmentSession(
                        data = session,
                        isSelected = session == selectedSession,
                        onClick = { selectedSession = session }
                    )
                }
            }
        }
    }

}
@Composable
fun CoachAssessmentSession(
    data : CoachSessionData,
    isSelected: Boolean,
    onClick: () -> Unit
) {
    val sitePalette = ColorMode.current.toSitePalette()
    Box(
        modifier = Modifier.fillMaxWidth()
            .borderRadius(8.px)
            .margin(bottom = 10.px)
            .backgroundColor(if (isSelected)sitePalette.selectedSessionBackground.rgb else Colors.Transparent)
            .padding(topBottom = 12.px, leftRight = 8.px)
            .onClick {  onClick() }
            .border(
                2.px,
                style = LineStyle.Solid,
                color = if (isSelected)sitePalette.primary.rgb else Colors.Transparent
            )
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
        ) {
            SpanText(
                data.sessionName,
                modifier = Modifier
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontWeight(500)
                    .fontSize(16.px)
                    .margin(bottom = 20.px)
            )

            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                SpanText(
                    data.coachName,
                    modifier = Modifier
                        .fontFamily(Constants.FONT_FAMILY)
                        .width(110.px)
                        .fontWeight(400)
                        .fontSize(13.px)
                )

                Row(
                    modifier = Modifier.width(100.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(src = Res.Images.timeCircle, modifier = Modifier.size(17.px))
                    CustomSpacer(5.px)
                    SpanText(
                        data.duration,
                        modifier = Modifier
                            .fontFamily(Constants.FONT_FAMILY)
                            .fontWeight(400)
                            .margin(right = 3.px)
                            .fontSize(13.px)
                    )
                }

                Row(
                    modifier = Modifier.width(150.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(src = Res.Images.calender, modifier = Modifier.size(17.px))
                    CustomSpacer(5.px)
                    SpanText(
                        "${data.date}, ${data.time}",
                        modifier = Modifier
                            .fontFamily(Constants.FONT_FAMILY)
                            .fontWeight(400)
                            .fontSize(13.px)
                    )
                }
                Row(
                    modifier = Modifier.margin(top = (-30).px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Box(
                        modifier = Modifier.width(30.px).height(30.px)
                            .borderRadius(100.px)
                            .margin(right = 10.px),
                        contentAlignment = Alignment.Center
                    ) {
                        Image(
                            src = Res.Images.downloadPrimary,
                            modifier = Modifier.size(26.px)
                                .cursor(Cursor.Pointer)
                        )
                    }
                    Box(contentAlignment = Alignment.Center) {
                        Image(
                            src = Res.Images.playbutton,
                            modifier = Modifier.size(26.px)
                                .cursor(Cursor.Pointer)
                        )
                    }


                }

            }
            Row(modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                verticalAlignment = Alignment.CenterVertically) {
                FaVideo(
                    modifier = Modifier.color(sitePalette.primary.rgb)
                        .width(15.px).height(12.px)
                )
                CustomSpacer(5.px)
                FieldTitleTextNormalSimple(
                    text = "Morning_Session.mp4",
                    fontSize = 12.px,
                    fontFamily = Constants.IBM_FONT,
                    marginRight = 7.px,
                    marginLeft = 7.px
                )
                Image(src = Res.Images.checkmark)
            }
            if (!isSelected) {
                Divider()
            }
        }
    }
}

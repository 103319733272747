package org.example.zone360.componentss

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode

import org.example.zone360.style.BaseTextStyle
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.jetbrains.compose.web.css.CSSLengthValue
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.px


@Composable
fun FieldTitleText(text: String, color: Color? = null, size: CSSLengthValue? = null) {
    val sitePalette = ColorMode.current.toSitePalette()

    SpanText(
        modifier = BaseTextStyle.toModifier()
            .margin(bottom = 20.px)
            .color(color ?: sitePalette.primary.rgb)
            .fontFamily(Constants.FONT_FAMILY)
            .fontSize(size ?: 21.px)
            .fontWeight(FontWeight.Normal)
            .overflow(Overflow.Hidden)
            .textOverflow(TextOverflow.Ellipsis)
            .styleModifier {
                property("display", "-webkit-box")
                property("-webkit-line-clamp", "2")
                property("line-clamp", "2")
                property("-webkit-box-orient", "vertical")
            },
        text = text
    )
}

@Composable
fun FieldTitleTextNormal(
    marginBottom: CSSLengthNumericValue? = null,
    marginTop: CSSLengthNumericValue? = null,
    textAlign: TextAlign = TextAlign.Start,
    text: String,
    color: Color? = null,
    fontSize: CSSLengthNumericValue? = null,
    isClickable: Boolean? = false,
    onClick: () -> Unit,
) {
    val sitePalette = ColorMode.current.toSitePalette()

    SpanText(
        modifier = Modifier
            .fillMaxWidth()
            .margin(bottom = marginBottom ?: 5.px, top = marginTop ?: 0.px)
            .color(color ?: sitePalette.textFieldTextColor.rgb)
            .fontFamily(Constants.FONT_FAMILY)
            .fontSize(fontSize ?: 12.px)
            .textAlign(textAlign)
            .fontWeight(FontWeight.Normal)
            .overflow(Overflow.Visible)
            .display(DisplayStyle.None)
            .textOverflow(TextOverflow.Ellipsis)
            .cursor(if (isClickable == true) Cursor.Pointer else Cursor.Default).onClick {
                println("text click")
                onClick()
            }
            .styleModifier {
                property(
                    "display",
                    "${if (text.isEmpty() || text.isBlank()) "none" else "-webkit-box"}"
                )
                property("-webkit-line-clamp", "2")
                property("line-clamp", "1")
                property("-webkit-box-orient", "vertical")
            },
        text = text
    )
}

@Composable
fun FieldTitleTextNormalSimple(
    text: String,
    width: CSSLengthOrPercentageNumericValue = 0.px,
    color: Color? = null,
    fontSize: CSSLengthNumericValue? = null,
    textAlign: TextAlign = TextAlign.Start,
    fontFamily: String = Constants.FONT_FAMILY,
    fontWeight: Int = 400,
    onClick: () -> Unit = {},
    isClickable: Boolean? = false,
    marginTop: CSSLengthNumericValue = 0.px,
    marginLeft: CSSLengthNumericValue = 0.px,
    marginRight: CSSLengthNumericValue = 0.px,
    marginBottom: CSSLengthNumericValue = 0.px,
    flexWrap: FlexWrap = FlexWrap.Nowrap,
    whiteSpace: WhiteSpace = WhiteSpace.Normal,
    onMouseEnter: (() -> Unit)? = null,
    onMouseLeave: (() -> Unit)? = null

    ) {
    val sitePalette = ColorMode.current.toSitePalette()
    val modifier=if(width==0.px) Modifier else Modifier.width(width)

    SpanText(
        modifier = modifier
            .margin(bottom = marginBottom, top = marginTop, left = marginLeft, right = marginRight)
            .color(color ?: sitePalette.textFieldTextColor.rgb)
            .fontFamily(fontFamily)
            .fontSize(fontSize ?: 13.px)
            .fontWeight(fontWeight)
            .textAlign(textAlign)
            .flexWrap(flexWrap)
            .onMouseEnter { onMouseEnter?.invoke() }
            .onMouseLeave { onMouseLeave?.invoke() }
            .whiteSpace(whiteSpace)
            .cursor(if (isClickable == true) Cursor.Pointer else Cursor.Default).onClick {
                println("text click")
                onClick()
            },
        text = text
    )
}
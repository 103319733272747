package org.example.zone360.pages.dashboard.playerAssessment

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseDown
import com.varabyte.kobweb.compose.ui.modifiers.onMouseUp
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.icons.fa.FaX
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.componentss.FieldTitleText
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetPlayerData
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H2
import org.w3c.dom.HTMLElement

@Composable
fun DeletePlayer(
    onDialogDismiss: (Long) -> Unit,
    breakpoint: Breakpoint,
    modalNumber: Long,
    toastMessageCall: (String) -> Unit,
    ){
    val scope = rememberCoroutineScope()
    val sitePalette = ColorMode.current.toSitePalette()
    var isLoading by remember { mutableStateOf(false) }

    var posX = 0
    var posY = 0
    var mouseX: dynamic
    var mouseY: dynamic


    val callback = { e: dynamic ->
        val draggable = document.getElementById("Delete-Player-modal-$modalNumber") as HTMLElement
        mouseX = e.clientX - posX
        mouseY = e.clientY - posY
        draggable.style.left = "${mouseX}px"
        draggable.style.top = "${mouseY}px"
    }
    val modifier = if (breakpoint <= Breakpoint.MD) Modifier.width(31.px).overflow(overflowX = Overflow.Auto, overflowY = Overflow.Hidden) else Modifier.width(22.percent)

    Box(
        modifier = modifier
            .width(300.px)
            .height(200.px)
            .id("Delete-Player-modal-$modalNumber")
            .position(Position.Fixed)
            .borderRadius(8.px)
            .zIndex(11)
            .top(50.percent)
            .left(50.percent)
            .transform { translate((-50).percent, (-50).percent) },
        contentAlignment = Alignment.Center,
    ) {
        Box(
            modifier = Modifier
                .width(90.percent)
                .height(90.percent)
                .onClick { }
        )
        Box(
            modifier = Modifier
                .padding(all = 24.px)
                .width(90.percent)
                .margin(top = 10.px)
                .height(90.percent)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .border(
                    width = 1.px,
                    color = sitePalette.textFieldBorderColor.rgb,
                    style = LineStyle.Solid
                )
                .borderRadius(4.px)
                .overflow(Overflow.Auto),
            contentAlignment = Alignment.Center
        ) {
            Column(modifier = Modifier.fillMaxSize(),
                horizontalAlignment = Alignment.CenterHorizontally,) {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 20.px)
                        .onMouseDown {
                            val draggable =
                                document.getElementById("Delete-Player-modal-$modalNumber") as HTMLElement
                            it.preventDefault()
                            posX = it.clientX - draggable.offsetLeft
                            posY = it.clientY - draggable.offsetTop
                            window.document.addEventListener("mousemove", callback)
                        }
                        .onMouseUp {
                            window.document.removeEventListener("mousemove", callback)
                        }
                        .cursor(Cursor.Move),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Column {
                            FieldTitleTextNormalSimple(
                                text = "Confirm Deletion",
                                width = 220.px,
                                marginTop = 10.px,
                                marginBottom = 10.px,
                                textAlign = TextAlign.Center,
                                fontWeight = 600,
                            )

                        FieldTitleTextNormalSimple(
                            text = "Are you sure you want to delete this player?",
                            width = 214.px,
                            fontWeight = 400,
                            textAlign = TextAlign.Center,
                        )
                        Row( modifier = Modifier.fillMaxWidth().margin(top = 30.px),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {

                            CommonButton(
                                isLoading = isLoading,
                                text = "Delete", onCLick = {
                                    scope.launch {
//                                        isLoading = true
//                                        val response =
//                                            deleteCategoryList(categoryId = "${categoryData.categoryId}")
//                                        isLoading = false
//                                        if (response.success == 1) {
//                                            toastMessageCall("Category Deleted Successfully")
//                                            (data as MutableList<CategoryData>).remove(categoryData)
//                                            onDialogDismiss(modalNumber)
//                                        } else {
//                                            toastMessageCall("Failed to delete category")
//                                        }

                                    }
                                },
                                backgroundColor = sitePalette.textRedColor.rgb,
                                width = 100.px,
                                height = 40.px
                            )
                           CommonButton(
                                text = "Cancel", onCLick = {
                                    onDialogDismiss(modalNumber)
                                },
                               textSpanColor = sitePalette.textFieldTextColor.rgb,
                               backgroundColor = Colors.Transparent,
                               borderColor = sitePalette.textFieldTextColor.rgb,
                                width = 100.px,
                                height = 40.px
                           )

                        }
                    }
                }
            }
        }
    }
}
package org.example.zone360.utils

import kotlinx.browser.localStorage
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.example.zone360.models.UserData

object Constants {
    const val PAGE_WIDTH = 2560
    const val HEADER_HEIGHT = 80
    const val DASHBOARD_HEADER_HEIGHT = 70
    const val FONT_FAMILY = "Inter";
    const val IBM_FONT = "IBM Plex Sans";
    const val EMAIL_REGEX = "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+.[A-Za-z0-9.-]"

    ///
    const val COLOR_MODE_KEY = "COLOR_MODE_KEY"

    ///
    const val USER_KEY = "USER_KEY"
}
object Res {
    // Images
    object Images {
        const val logosvg = "logo.svg"
        const val logopng = "logo.png"
        const val faviconico = "favicon.ico"
        const val whistle = "whistle.svg"
        const val footballicon = "football.svg"
        const val matteo = "matteo.svg"
        const val alex = "alex.svg"
        const val brianPic = "briananderson.svg"
        const val michealPic = "michealbrown.svg"
        const val johnPic = "johnsmith.svg"
        const val kevinPic = "kevin.svg"
        const val playbutton = "playbutton.svg"
        const val pausebutton = "pausebutton.svg"
        const val groupGraph = "graphgroup.svg"
        const val tactical = "tactical.svg"
        const val technical = "technical.svg"
        const val fitness = "fitness.svg"
        const val mental = "mental.svg"
        const val goalkeeper = "goalkeeper.svg"
        const val blueCircle = "bluecircle.svg"
        const val club_Performance_Chart = "club_performance_chart.svg"
        const val updated_graph_var = " updated_graph_var.png"
        const val line = "line.svg"
        const val technicalChart = "technicalChart.svg"
        const val tacticalChart = "tactical_Chart.svg"
        const val mentalChart = "mentalChart.svg"
        const val fitnessChart = "fitnessChart.svg"
        const val goalkeeperChart = "goalkeeperChart.svg"
        const val userIcon = "userIcon.png"
        const val timeCircle = "Time_circle.svg"
        const val calender = "Calendar.svg"
        const val coachImageUrl = "https://zone-360-images.s3.us-south.cloud-object-storage.appdomain.cloud/"
        const val cornerResize = "cornerResize.png"
        const val coachPic = "coachPic.svg"
        const val keywordIcon = "keywordIcon.svg"
        const val nationalityFlag = "nationality.svg"
        const val teamIcon = "teamIcon.svg"
        const val downloadPrimary = "downloadPrimary.svg"
        const val checkmark = "check_solid.svg"
        const val recording_Voice = "record_voice_over.svg"
        const val percentageChart = "percentagechart-graphic.svg"
        const val spiderChart = "spider_chart.svg"
        const val toneChart = "tone_chart.svg"
        const val recordingPlay = "recording_playing.svg"
        const val sentimental_Ratio = "sentimentalRatio.svg"
        const val play_all_button = "play_all_button.svg"
        const val excitedEmoji = "excitedEmoji.svg"
        const val positiveEmoji = "positiveEmoji.svg"

    }


    object Id {

        // Input IDs
        const val usernameInput = "usernameInput"
        const val emailInput = "emailInput"
        const val passwordInput = "passwordInput"
        const val userSearchInput = "userSearchInput"
        const val coachName = "coachName"
        const val firstName = "firstNameInput"
        const val lastName = "lastNameInput"
        const val orginization = "organizationInput"
        const val fileInput = "fileInput"

    }

    object Audio{
        const val recording_Audio = "https://cloud-object-storage-cos-standard-audio-storage.s3.us-south.cloud-object-storage.appdomain.cloud/"
    }
}

object URL {
    const val platformUrl = "https://varcity.io/platform"
    const val pricingUrl = "https://varcity.io/pricing"
    const val companyUrl = "https://varcity.io/company"
    const val careerUrl = "https://varcity.io/careers"
    const val supportUrl = "https://sites.google.com/varcity.io/varcitysupport/home"
    const val contactUrl = "https://varcity.io/contactUs"
    const val partnersUrl = "https://varcity.io/partners"

}

enum class TimePeriod{
    OneMonth, ThreeMonth, SixMonth, OneYear, AllTime
}
enum class SelectedLanguage{
    English, Italian
}
enum class SelectedData{
    Training, Matches
}

class AppGlobal {
    companion object {
        var userData: UserData
            get() {
                val data = localStorage.getItem("userData")
                return if (data != null) {
                    Json.decodeFromString(data)
                } else {
                    UserData()
                }
            }
            set(value) {
                localStorage.setItem("userData", Json.encodeToString(value))
            }
    }
}
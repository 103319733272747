package org.example.zone360.pages.coachDetails

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.pages.coachDetails.coachInfo.CoachInfo
import org.example.zone360.pages.coachDetails.coachSession.CoachesSession
import org.example.zone360.pages.coachDetails.sessionAllDetails.SessionAllDetails
import org.example.zone360.sections.DashboardHeaderSection
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.px

@Page("/coach-details")
@Composable
fun CoachDetails(){
    val ctx = rememberPageContext()
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    val sitePalette = ColorMode.current.toSitePalette()
    Box(Modifier.fillMaxSize()) {
        Column(
            Modifier.fillMaxSize().backgroundColor(sitePalette.bodyBackground.rgb)
                .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Auto)
                .id("dashboardPage")
        ) {
            DashboardHeaderSection()
        Column(
            modifier = Modifier.fillMaxSize()
                .padding(leftRight = if (breakpoint >= Breakpoint.LG) 60.px else 20.px)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth().margin(top = 30.px, bottom = 10.px),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                FaArrowLeft()
                FieldTitleTextNormalSimple(
                    text = "Coach Details",
                    fontWeight = 500,
                    fontSize = 16.px,
                    marginLeft = 15.px
                )
            }
            CoachInfo(breakpoint, sitePalette)
          Row (modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px).flexWrap(FlexWrap.Wrap),
              horizontalArrangement = Arrangement.SpaceBetween){
              CoachesSession(breakpoint, sitePalette)
              SessionAllDetails(breakpoint, sitePalette)
          }

        }

        }
    }
}
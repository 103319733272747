package org.example.zone360.pages.dashboard.clubPerformance

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.models.GetClubPerformanceData
import org.example.zone360.models.GetClubPerformanceResponse
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.pages.dashboard.chart.LineChartPage
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun ClubPerformance(performanceData: GetClubPerformanceData,
                    breakpoint: Breakpoint,
                    selectedLanguage: SelectedLanguage,
                    isLoader: Boolean){
    val sitePalette = ColorMode.current.toSitePalette()
    Column   (modifier = Modifier
        .width(if (breakpoint > Breakpoint.LG) 68.percent else if(breakpoint == Breakpoint.LG) 59.percent
        else if(breakpoint >= Breakpoint.MD)54.percent else 100.percent)
        .height(425.px)
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .borderRadius(15.px)
        .overflow(
            overflowY = Overflow.Hidden,
            overflowX = Overflow.Auto
        )
        .padding(topBottom = 15.px, leftRight = 50.px)
    ) {
        Column(
            modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .margin(topBottom = 10.px)
                .fillMaxHeight()
        ){
        SectionTitle("Club Performance")
            if (isLoader) {
                Box (modifier = Modifier.fillMaxSize(),
                    contentAlignment = Alignment.Center){
                    ProgressIndicator(50.px)
                }
            } else{
            Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceBetween) {

            LineChartPage(breakpoint, performanceData, selectedLanguage)
//                CustomSpacer(20.px)
                Column (modifier = Modifier.fillMaxHeight().width(100.px),
                    horizontalAlignment = Alignment.Start,
                    verticalArrangement = Arrangement.SpaceEvenly){
                    Image(src = Res.Images.tacticalChart)
                    Image(src = Res.Images.technicalChart)
                    Image(src = Res.Images.mentalChart)
                    Image(src = Res.Images.fitnessChart)
                    Image(src = Res.Images.goalkeeperChart)
                }
                    }
        }
    }
}
}

package org.example.zone360.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.animation
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.example.zone360.style.Spin
import org.example.zone360.theme.toSitePalette
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s

@Composable
fun ProgressIndicator(
    size: CSSLengthNumericValue = 30.px,
    color: Color? = null
) {
    val sitePalette = ColorMode.current.toSitePalette()

    Box(contentAlignment = Alignment.Center, modifier = Modifier.height(size).width(size).animation(
        Spin.toAnimation(
            iterationCount = AnimationIterationCount.Infinite,
            duration = 1.s
        )
    )
//            .border(
//                width = 16.px,
//                style = LineStyle.Solid,
//                color = LightTheme.Red.rgb
//            ).borderRadius(r = 100.px).fontFamily(Constants.FONT_FAMILY).animation(
//                CSSAnimation(
//                    name = "spin",
//                    direction = AnimationDirection.Normal,
//                    playState = AnimationPlayState.Running
//                )
//            )
        .styleModifier {
            property(
                "border",
                "3px solid #f3f3f3"
            )
            property("border-top", "3px solid ${color?:sitePalette.primary.hex}")
            property("border-radius", "50%")
            property("animation", "spin 1s linear infinite")
            property("-webkit-animation", "spin 1s linear infinite")

        }) { }

}
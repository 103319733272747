package org.example.zone360.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Style

@Composable
fun CustomScrollBar() {
    Style {

        "*" {

            property("scrollbar-width", "thin")
            property("scrollbar-color", "#cccccc #f1f1f1")
        }

        "::-webkit-scrollbar" {
            property("width", "10px")
        }

        "::-webkit-scrollbar-track" {
            property("background", "#f1f1f1")
        }

        "::-webkit-scrollbar-thumb" {
            property("background", "#cccccc")
            property("border-radius", "5px")
        }

        "::-webkit-scrollbar-thumb:hover" {
            property("background", "#555")
        }
    }
}
package org.example.zone360.pages.coachDetails.sessionAllDetails

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaHourglassEnd
import com.varabyte.kobweb.silk.components.icons.fa.FaInfo
import com.varabyte.kobweb.silk.components.icons.fa.FaPlay
import com.varabyte.kobweb.silk.components.icons.fa.FaShareNodes
import com.varabyte.kobweb.silk.components.icons.fa.FaSortDown
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import kotlinx.browser.document
import org.example.zone360.components.CommonButton
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.SearchBar
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Divider
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.HTMLInputElement
import org.w3c.files.FileReader
import org.w3c.files.get

@Composable
fun SessionAllDetails(breakpoint: Breakpoint, sitePalette: SitePalette){
    var searchText by remember { mutableStateOf("") }
    var uploadedVideoUrl by remember { mutableStateOf<String?>(null) }
    Box(
        modifier = Modifier
            .width(if (breakpoint > Breakpoint.LG) 65.percent else if(breakpoint == Breakpoint.LG) 59.percent
            else if(breakpoint >= Breakpoint.MD)54.percent else 1360.px)
            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
            .borderRadius(15.px)
            .overflow(
                overflowY = Overflow.Hidden,
                overflowX =   Overflow.Auto
            )
            .padding(
                leftRight = if (breakpoint >= Breakpoint.LG) 30.px else 20.px,
                topBottom = if (breakpoint >= Breakpoint.LG) 20.px else 10.px
            )
    ) {
        Column(
            modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .fillMaxHeight()
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                SectionTitle("Morning Session")
                CommonButton(
                    text = "Upload Video",
                    icon = { FaUpload(size = IconSize.LG, modifier = Modifier.color(sitePalette.primary.rgb)) },
                    width = 150.px,
                    height = 40.px,
                    borderColor = sitePalette.primary.rgb,
                    backgroundColor = Colors.Transparent,
                    textSpanColor = sitePalette.primary.rgb,
                    onCLick = {
                        val fileInput = document.getElementById(Res.Id.fileInput) as HTMLInputElement
                        fileInput.click()
                    })
            }
            Row(modifier = Modifier.fillMaxWidth().gap(36.px),
                verticalAlignment = Alignment.CenterVertically) {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Image(
                        src = Res.Images.coachPic,
                        modifier = Modifier.size(30.px).margin(right = 8.px)
                            .borderRadius(100.px).textAlign(TextAlign.Start)
                            .border(
                                width = 2.px,
                                color = sitePalette.primary.rgb,
                                style = LineStyle.Solid
                            )
                    )
                    FieldTitleTextNormalSimple(
                        text = "Coach: ",
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 18.px
                    )
                    FieldTitleTextNormalSimple(
                        text = " John Smith",
                        fontWeight = 600,
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 18.px
                    )
                }

                Row(   verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(src = Res.Images.timeCircle, modifier = Modifier.size(17.px))
                    CustomSpacer(5.px)
                    FieldTitleTextNormalSimple(
                        text = "Duration: ",
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 18.px
                    )
                    FieldTitleTextNormalSimple(
                        text = " 8:55",
                        fontWeight = 600,
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 18.px
                    )
                }
                Row(verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(src = Res.Images.calender, modifier = Modifier.size(17.px))
                    CustomSpacer(5.px)
                    FieldTitleTextNormalSimple(
                        text = "Recorded: ",
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 18.px
                    )
                    FieldTitleTextNormalSimple(
                        text = " Sunday, Nov 7, 2023",
                        fontWeight = 600,
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 18.px
                    )
                }
            }
            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween) {

                Box (modifier = Modifier.width(49.percent)
                    .height(220.px)
                    .margin(top = 15.px)
                    .borderRadius(12.px)
                    .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
                    .padding(16.px)
                    .boxShadow(
                        offsetY = 1.px,
                        blurRadius = 0.px,
                        color = sitePalette.fadedBlack.rgb

                    )
                ){
                    SectionTitle("Speaking")
                    Column(modifier = Modifier.fillMaxSize(),
                        verticalArrangement = Arrangement.Center) {
                        Row (modifier = Modifier.fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween){
                            Column (modifier = Modifier.gap(24.px).fillMaxWidth()){
                                Row (verticalAlignment = Alignment.CenterVertically){
                                    FaHourglassEnd(modifier = Modifier.color(sitePalette.primary.rgb).margin(right = 4.px, bottom = (-7).px), size = IconSize.LG)
                                    FieldTitleTextNormalSimple(
                                        text = "Total Duration: ",
                                        fontFamily = Constants.IBM_FONT,
                                        fontSize = 18.px
                                    )
                                    FieldTitleTextNormalSimple(
                                        text = " 8:55 mins",
                                        fontWeight = 600,
                                        fontFamily = Constants.IBM_FONT,
                                        fontSize = 18.px
                                    )
                                }
                                Row (verticalAlignment = Alignment.CenterVertically){
                                    Image(src = Res.Images.recording_Voice, modifier = Modifier.size(17.px).margin(right = 4.px))
                                    FieldTitleTextNormalSimple(
                                        text = "Speaking: ",
                                        fontFamily = Constants.IBM_FONT,
                                        fontSize = 18.px
                                    )
                                    FieldTitleTextNormalSimple(
                                        text = " 7:05 mins",
                                        fontWeight = 600,
                                        fontFamily = Constants.IBM_FONT,
                                        fontSize = 18.px
                                    )
                                }
                            }
                            Image(src = Res.Images.percentageChart, modifier = Modifier.width(129.px).height(120.px))
                        }


                    }
                }

                Box (modifier = Modifier.width(49.percent)
                    .height(220.px)
                    .margin(top = 15.px)
                    .borderRadius(12.px)
                    .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
                    .padding(16.px)
                    .boxShadow(
                        offsetY = 1.px,
                        blurRadius = 0.px,
                        color = sitePalette.fadedBlack.rgb

                    )
                ){
                    Column {
                    Row (modifier = Modifier.fillMaxWidth()){
                        SectionTitle("Key Words: 24")
                        Box (modifier = Modifier
                            .width(14.px)
                            .height(14.px)
                            .backgroundColor(Colors.LightGray)
                            .borderRadius(100.px)
                            .margin(leftRight = 5.px),
                            contentAlignment = Alignment.Center
                        ) {
                            FaInfo(modifier = Modifier.color(Colors.White)
                                .cursor(Cursor.Pointer), size = IconSize.XXS)
                        }

                    }
                    Row(modifier = Modifier.gap(12.px),
                        verticalAlignment = Alignment.CenterVertically) {
                        Row {
                            FieldTitleTextNormalSimple(
                                text = "Total Words: ",
                                fontFamily = Constants.IBM_FONT,
                                fontSize = 14.px
                            )
                            FieldTitleTextNormalSimple(
                                text = " 1682",
                                fontWeight = 600,
                                fontFamily = Constants.IBM_FONT,
                                fontSize = 14.px
                            )
                        }
                        Row {
                            FieldTitleTextNormalSimple(
                                text = "Emphasis: ",
                                fontFamily = Constants.IBM_FONT,
                                fontSize = 14.px
                            )
                            FieldTitleTextNormalSimple(
                                text = " Tactical",
                                fontWeight = 600,
                                fontFamily = Constants.IBM_FONT,
                                fontSize = 14.px
                            )
                        }

                    }
                        Row(modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px).flexWrap(FlexWrap.Wrap)) {
                            Box(
                                modifier = Modifier.backgroundColor(sitePalette.mentalCategoryBackground.rgb)
                                    .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                    .border(1.px, style = LineStyle.Solid , color = sitePalette.mentalCategoryBorder.rgb)
                            ) {
                                FieldTitleTextNormalSimple(
                                    "flip(2)",
                                    fontSize = 12.px,
                                    fontFamily = Constants.IBM_FONT,
                                )
                            }
                            Box(
                                modifier = Modifier.backgroundColor(sitePalette.goalKeeperCategoryBackground.rgb)
                                    .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                    .border(1.px, style = LineStyle.Solid, color = sitePalette.goalKeeperCategoryBorder.rgb)
                            ) {
                                FieldTitleTextNormalSimple(
                                    "strategy(9)",
                                    fontSize = 12.px,
                                    fontFamily = Constants.IBM_FONT,
                                )
                            }
                            Box(
                                modifier = Modifier.backgroundColor(sitePalette.mentalCategoryBackground.rgb)
                                    .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                    .border(1.px, style = LineStyle.Solid, color = sitePalette.mentalCategoryBorder.rgb)
                            ) {
                                FieldTitleTextNormalSimple(
                                    "failure(2)",
                                    fontSize = 12.px,
                                    fontFamily = Constants.IBM_FONT,
                                )
                            }
                            Box(
                                modifier = Modifier.backgroundColor(sitePalette.fitnessCategoryBackground.rgb)
                                    .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                    .border(1.px, style = LineStyle.Solid, color = sitePalette.fitnessCategoryBorder.rgb)
                            ) {
                                FieldTitleTextNormalSimple(
                                    "persistence(10)",
                                    fontSize = 12.px,
                                    fontFamily = Constants.IBM_FONT,
                                )
                            }
                            Box(
                                modifier = Modifier.backgroundColor(sitePalette.goalKeeperCategoryBackground.rgb)
                                    .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                    .border(1.px, style = LineStyle.Solid, color = sitePalette.goalKeeperCategoryBorder.rgb)
                            ) {
                                FieldTitleTextNormalSimple(
                                    "attack(11)",
                                    fontSize = 12.px,
                                    fontFamily = Constants.IBM_FONT,
                                )
                            }
                            Box(
                                modifier = Modifier.backgroundColor(sitePalette.tacticalCategoryBackground.rgb)
                                    .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                    .border(1.px, style = LineStyle.Solid, color = sitePalette.tacticalCategoryBorder.rgb)
                            ) {
                                FieldTitleTextNormalSimple(
                                    "success(2)",
                                    fontSize = 12.px,
                                    fontFamily = Constants.IBM_FONT,
                                )
                            }
                            Box(
                                modifier = Modifier.backgroundColor(sitePalette.technicalCategoryBackground.rgb)
                                    .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                    .border(1.px, style = LineStyle.Solid, color = sitePalette.technicalCategoryBorder.rgb)
                            ) {
                                FieldTitleTextNormalSimple(
                                    "strength(5)",
                                    fontSize = 12.px,
                                    fontFamily = Constants.IBM_FONT,
                                )
                            }
                            Box(
                                modifier = Modifier.backgroundColor(sitePalette.fitnessCategoryBackground.rgb)
                                    .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                    .border(1.px, style = LineStyle.Solid, color = sitePalette.fitnessCategoryBorder.rgb)
                            ) {
                                FieldTitleTextNormalSimple(
                                    "mindset(4)",
                                    fontSize = 12.px,
                                    fontFamily = Constants.IBM_FONT,
                                )
                            }

                            Box(
                                modifier = Modifier.backgroundColor(sitePalette.mentalCategoryBackground.rgb)
                                    .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                    .border(1.px, style = LineStyle.Solid, color = sitePalette.mentalCategoryBorder.rgb)
                            ) {
                                FieldTitleTextNormalSimple(
                                    "focus(12)",
                                    fontSize = 12.px,
                                    fontFamily = Constants.IBM_FONT,
                                )
                            }

                        }
                    }
                }
            }
            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween) {

                Box (modifier = Modifier.width(49.percent)
                    .height(322.px)
                    .margin(top = 15.px)
                    .borderRadius(12.px)
                    .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
                    .padding(16.px)
                    .boxShadow(
                        offsetY = 1.px,
                        blurRadius = 0.px,
                        color = sitePalette.fadedBlack.rgb

                    )
                ){
                    Column {
                        SectionTitle("Emotion")
                        Column (modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ){
                            Image(src = Res.Images.spiderChart, modifier = Modifier.width(100.percent))
                        }
                    }
                }
                Box (modifier = Modifier.width(49.percent)
                    .height(322.px)
                    .margin(top = 15.px)
                    .borderRadius(12.px)
                    .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
                    .padding(16.px)
                    .boxShadow(
                        offsetY = 1.px,
                        blurRadius = 0.px,
                        color = sitePalette.fadedBlack.rgb

                    )
                ){
                    Column {
                        SectionTitle("Sentiments")
                        Image(src = Res.Images.sentimental_Ratio, modifier = Modifier.width(100.percent))
                        Row(modifier = Modifier.fillMaxWidth().gap(36.px),
                            verticalAlignment = Alignment.CenterVertically,) {
                            Row (verticalAlignment = Alignment.CenterVertically){
                                Box(modifier = Modifier.size(10.px).backgroundColor(Colors.Green))
                                FieldTitleTextNormalSimple(
                                    text = "Positive: 60%",
                                    marginLeft = 10.px,
                                    fontSize = 12.px
                                )
                            }
                            Row (verticalAlignment = Alignment.CenterVertically){
                                Box(modifier = Modifier.size(10.px).backgroundColor(Colors.Red))
                                FieldTitleTextNormalSimple(
                                    text = "Negative: 30%",
                                    marginLeft = 10.px,
                                    fontSize = 12.px
                                )
                            }
                            Row (verticalAlignment = Alignment.CenterVertically){
                                Box(modifier = Modifier.size(10.px).backgroundColor(Colors.Orange))
                                FieldTitleTextNormalSimple(
                                    text = "Neutral: 10%",
                                    marginLeft = 10.px,
                                    fontSize = 12.px
                                )
                            }
                        }
                        Box(Modifier.height(10.px)) {  }
                        SectionTitle("Tone")
                        Image(src = Res.Images.toneChart, modifier = Modifier.width(100.percent))
                    }
                    }
            }
            Box (modifier = Modifier.fillMaxWidth()
                .height(850.px)
                .margin(top = 15.px)
                .borderRadius(12.px)
                .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
                .padding(16.px)
                .boxShadow(
                    offsetY = 1.px,
                    blurRadius = 0.px,
                    color = sitePalette.fadedBlack.rgb)) {
                Column(modifier = Modifier.fillMaxSize()) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        SectionTitle("Highlights")
                        Row(
                            modifier = Modifier
                                .height(24.px)
                                .borderRadius(4.px)
                                .backgroundColor(sitePalette.searchBackground.rgb)
                                .padding(topBottom = 10.px, leftRight = 8.px)
                                .gap(8.px),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Box (contentAlignment = Alignment.Center){
                                Image(
                                    src = Res.Images.play_all_button,
                                    modifier = Modifier.borderRadius(100.px)
                                )
                                FaPlay(modifier = Modifier.color(Palette.White.rgbLight), size = IconSize.XXS)
                            }
                            FieldTitleTextNormalSimple(
                                "Play All",
                                fontWeight = 500,
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT
                            )

                        }
                    }
                    Image(src = Res.Images.recordingPlay)
                    Row (modifier = Modifier.fillMaxWidth()){
                        Box(
                            modifier = Modifier.backgroundColor(sitePalette.fitnessCategoryBackground.rgb)
                                .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                .padding(leftRight = 8.px, topBottom = 4.px)
                                .border(
                                    1.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.fitnessCategoryBorder.rgb
                                )
                        ) {
                            FieldTitleTextNormalSimple(
                                "persistence",
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT,
                            )
                        }
                        Box(
                            modifier = Modifier.backgroundColor(sitePalette.goalKeeperCategoryBackground.rgb)
                                .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                .padding(leftRight = 8.px, topBottom = 4.px)
                                .border(
                                    1.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.goalKeeperCategoryBorder.rgb
                                )
                        ) {
                            FieldTitleTextNormalSimple(
                                "attack",
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT,
                            )
                        }
                        Box(
                            modifier = Modifier.backgroundColor(sitePalette.tacticalCategoryBackground.rgb)
                                .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                .padding(leftRight = 8.px, topBottom = 4.px)
                                .border(
                                    1.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.tacticalCategoryBorder.rgb
                                )
                        ) {
                            FieldTitleTextNormalSimple(
                                "success",
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT,
                            )
                        }
                    }
                    FieldTitleTextNormalSimple(
                        "Tag Player",
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 12.px,
                        fontWeight = 600,
                        marginBottom = 10.px,
                        marginTop = 10.px
                    )
                    SearchBar(
                        placeholder = "Search...",
                        id = "session_Searchbar",
                        breakpoint = Breakpoint.LG,
                        onEnterClick = {},
                        onChange = {},
                        onClick = {},
                        width = 390.px,
                        showClearButton = true,
                        height = 48.px,
                        borderColor = Colors.Transparent
                    )
                    Row (modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically){
                        Row {
                        Image(src = Res.Images.positiveEmoji)
                        FieldTitleTextNormalSimple(
                            text = " Positive ",
                            fontSize = 12.px,
                            marginLeft = 3.px,
                            fontWeight = 500,
                            color = Colors.Green,
                            fontFamily = Constants.IBM_FONT)
                        FieldTitleTextNormalSimple(
                            text = ".",
                            fontSize = 12.px,
                            fontWeight = 500,
                            marginLeft = 5.px,
                            marginRight = 5.px,
                            color = Colors.LightGrey,
                            fontFamily = Constants.IBM_FONT)
                        Image(src = Res.Images.excitedEmoji)
                        FieldTitleTextNormalSimple(
                            text = " Excited ",
                            marginLeft = 3.px,
                            fontSize = 12.px,
                            fontWeight = 500,
                            fontFamily = Constants.IBM_FONT)
                    }
                        Row {
                            FaSortDown()
                            CustomSpacer(50.px)
                            FaShareNodes()
                        }
                    }

                    Divider()
                    CustomSpacer(height = 10.px)
                    Image(src = Res.Images.recordingPlay)
                    Row (modifier = Modifier.fillMaxWidth()){
                        Box(
                            modifier = Modifier.backgroundColor(sitePalette.fitnessCategoryBackground.rgb)
                                .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                .padding(leftRight = 8.px, topBottom = 4.px)
                                .border(
                                    1.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.fitnessCategoryBorder.rgb
                                )
                        ) {
                            FieldTitleTextNormalSimple(
                                "persistence",
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT,
                            )
                        }
                        Box(
                            modifier = Modifier.backgroundColor(sitePalette.goalKeeperCategoryBackground.rgb)
                                .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                .padding(leftRight = 8.px, topBottom = 4.px)
                                .border(
                                    1.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.goalKeeperCategoryBorder.rgb
                                )
                        ) {
                            FieldTitleTextNormalSimple(
                                "attack",
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT,
                            )
                        }
                        Box(
                            modifier = Modifier.backgroundColor(sitePalette.tacticalCategoryBackground.rgb)
                                .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                .padding(leftRight = 8.px, topBottom = 4.px)
                                .border(
                                    1.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.tacticalCategoryBorder.rgb
                                )
                        ) {
                            FieldTitleTextNormalSimple(
                                "success",
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT,
                            )
                        }
                    }
                    FieldTitleTextNormalSimple(
                        "Tag Player",
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 12.px,
                        fontWeight = 600,
                        marginBottom = 10.px,
                        marginTop = 10.px
                    )
                    SearchBar(
                        placeholder = "Search...",
                        id = "session_Searchbar",
                        breakpoint = Breakpoint.LG,
                        onEnterClick = {},
                        onChange = {},
                        onClick = {},
                        width = 390.px,
                        showClearButton = true,
                        height = 48.px,
                        borderColor = Colors.Transparent
                    )
                    Row (modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically){
                        Row {
                            Image(src = Res.Images.positiveEmoji)
                            FieldTitleTextNormalSimple(
                                text = " Positive ",
                                fontSize = 12.px,
                                marginLeft = 3.px,
                                fontWeight = 500,
                                color = Colors.Green,
                                fontFamily = Constants.IBM_FONT)
                            FieldTitleTextNormalSimple(
                                text = ".",
                                fontSize = 12.px,
                                fontWeight = 500,
                                marginLeft = 5.px,
                                marginRight = 5.px,
                                color = Colors.LightGrey,
                                fontFamily = Constants.IBM_FONT)
                            Image(src = Res.Images.excitedEmoji)
                            FieldTitleTextNormalSimple(
                                text = " Excited ",
                                marginLeft = 3.px,
                                fontSize = 12.px,
                                fontWeight = 500,
                                fontFamily = Constants.IBM_FONT)
                        }
                        Row {
                            FaSortDown()
                            CustomSpacer(50.px)
                            FaShareNodes()
                        }
                    }

                    Divider()
                    CustomSpacer(height = 10.px)
                    Image(src = Res.Images.recordingPlay)
                    Row (modifier = Modifier.fillMaxWidth()){
                        Box(
                            modifier = Modifier.backgroundColor(sitePalette.fitnessCategoryBackground.rgb)
                                .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                .padding(leftRight = 8.px, topBottom = 4.px)
                                .border(
                                    1.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.fitnessCategoryBorder.rgb
                                )
                        ) {
                            FieldTitleTextNormalSimple(
                                "persistence",
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT,
                            )
                        }
                        Box(
                            modifier = Modifier.backgroundColor(sitePalette.goalKeeperCategoryBackground.rgb)
                                .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                .padding(leftRight = 8.px, topBottom = 4.px)
                                .border(
                                    1.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.goalKeeperCategoryBorder.rgb
                                )
                        ) {
                            FieldTitleTextNormalSimple(
                                "attack",
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT,
                            )
                        }
                        Box(
                            modifier = Modifier.backgroundColor(sitePalette.tacticalCategoryBackground.rgb)
                                .borderRadius(7.px).margin(leftRight = 5.px, topBottom = 7.px)
                                .padding(leftRight = 8.px, topBottom = 4.px)
                                .border(
                                    1.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.tacticalCategoryBorder.rgb
                                )
                        ) {
                            FieldTitleTextNormalSimple(
                                "success",
                                fontSize = 12.px,
                                fontFamily = Constants.IBM_FONT,
                            )
                        }
                    }
                    FieldTitleTextNormalSimple(
                        "Tag Player",
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 12.px,
                        fontWeight = 600,
                        marginBottom = 10.px,
                        marginTop = 10.px
                    )
                    SearchBar(
                        placeholder = "Search...",
                        id = "session_Searchbar",
                        breakpoint = Breakpoint.LG,
                        onEnterClick = {},
                        onChange = {},
                        onClick = {},
                        width = 390.px,
                        showClearButton = true,
                        height = 48.px,
                        borderColor = Colors.Transparent
                    )
                    Row (modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically){
                        Row {
                            Image(src = Res.Images.positiveEmoji)
                            FieldTitleTextNormalSimple(
                                text = " Positive ",
                                fontSize = 12.px,
                                marginLeft = 3.px,
                                fontWeight = 500,
                                color = Colors.Green,
                                fontFamily = Constants.IBM_FONT)
                            FieldTitleTextNormalSimple(
                                text = ".",
                                fontSize = 12.px,
                                fontWeight = 500,
                                marginLeft = 5.px,
                                marginRight = 5.px,
                                color = Colors.LightGrey,
                                fontFamily = Constants.IBM_FONT)
                            Image(src = Res.Images.excitedEmoji)
                            FieldTitleTextNormalSimple(
                                text = " Excited ",
                                marginLeft = 3.px,
                                fontSize = 12.px,
                                fontWeight = 500,
                                fontFamily = Constants.IBM_FONT)
                        }
                        Row {
                            FaSortDown()
                            CustomSpacer(50.px)
                            FaShareNodes()
                        }
                    }

                    Divider()

                }
            }
            Box (modifier = Modifier.fillMaxWidth()

                .margin(top = 15.px)
                .borderRadius(12.px)
                .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
                .padding(16.px)
                .boxShadow(
                    offsetY = 1.px,
                    blurRadius = 0.px,
                    color = sitePalette.fadedBlack.rgb)) {
                Column {
                    SectionTitle("Summary")
                    FieldTitleTextNormalSimple(
                        width = 100.percent,
                        whiteSpace = WhiteSpace.PreWrap,
                        fontFamily = Constants.IBM_FONT,
                        fontSize = 16.px,
                        text = "The coach is giving advice to the players, emphasizing the importance " +
                                "of ball control and accuracy. The coach tells the player to focus on the fundamentals," +
                                " maintain good touch, and have a full range of motion when controlling the ball. The coach " +
                                "also encourages the player to be free and confident, reminding them that they have the skills to perform well.\n" +
                                "The coach also uses phrases such as \"quick, quick, quick\" to suggest that the player needs to" +
                                " move quickly and be proactive on the field. Additionally, the coach tells the player " +
                                "to \"draw them out\" suggesting that the player needs to be strategic and force opponent to move " +
                                "first. The coach uses soccer-related words such as \"finishing\" and \"game.\" The coach also gives " +
                                "mental advice, such as \"good job,\" \"brave,\" and \"thank you.\" The coach is trying to motivate " +
                                "the players and help them build confidence. The coach concludes by expressing the need for more " +
                                "accuracy and repetition in training."
                    )
                }

            }
        }
    }
    Input(
        type = InputType.File,
        attrs = {
            id(Res.Id.fileInput)
            style {
                display(DisplayStyle.None)
            }
            onChange { event ->
                val files = (event.target ).files
                if (files != null && files.length > 0) {
                    val file = files[0]
                    if (file != null) {
                        val reader = FileReader()
                        reader.onload = { e ->
                            val result = e.target?.asDynamic()?.result as? String
                            if (result != null) {

                                uploadedVideoUrl = result
                            }
                        }
                        reader.readAsDataURL(file)
                    }
                }
            }
        }
    )
}
package org.example.zone360.pages.coachDetails.coachInfo

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaClock
import com.varabyte.kobweb.silk.components.icons.fa.FaFutbol
import com.varabyte.kobweb.silk.components.icons.fa.FaInfo
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.icons.fa.IconStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CoachInfo(breakpoint: Breakpoint, sitePalette: SitePalette){
    Box (modifier = Modifier.fillMaxWidth()
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .overflow(overflowX = Overflow.Auto, overflowY = Overflow.Hidden)
        .borderRadius(11.34.px)
        .padding(leftRight = 10.px)
        .boxShadow(
          offsetX = 0.px,
            offsetY = 2.px,
            blurRadius = 16.px,
            spreadRadius = null,
            color = sitePalette.boxShadowColor.rgb
        )){
        Column(
            modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .fillMaxHeight()
        ) {
     Box (modifier = Modifier.width(98.8.percent)) {

             Column(
                 modifier = Modifier.fillMaxWidth()
                     .padding(topBottom = 20.px)
             ) {
                 Row(modifier = Modifier.padding(leftRight = 20.px)) {
                     Image(
                         src = Res.Images.coachPic,
                         modifier = Modifier.width(120.px)
                             .height(120.px)
                             .borderRadius(100.px)
                     )
                     Column(modifier = Modifier.margin(leftRight = 20.px)) {
                         FieldTitleTextNormalSimple(
                             text = "Raha Shams",
                             fontSize = 20.px,
                             fontWeight = 600,
                             fontFamily = Constants.IBM_FONT
                         )
                         Row(
                             modifier = Modifier.margin(topBottom = 10.px),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             FaFutbol(modifier = Modifier.color(Colors.Gray))
                             FieldTitleTextNormalSimple(
                                 text = " Head Coach - Juventus Academy",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                                 marginLeft = 8.px
                             )
                         }
                         Row(
                             modifier = Modifier.margin(topBottom = 10.px),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             Image(src = Res.Images.nationalityFlag)
                             FieldTitleTextNormalSimple(
                                 text = " Nationality: United States",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                                 marginLeft = 8.px
                             )
                         }
                         Row(
                             modifier = Modifier.margin(topBottom = 10.px),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             Image(src = Res.Images.teamIcon)
                             FieldTitleTextNormalSimple(
                                 text = "Teams Managing: Juventus Academy, ABC Coaching Academy, XYZ Coaching",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                                 marginLeft = 8.px
                             )
                         }
                     }
                 }
                 Row(
                     modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px, leftRight = 20.px)
                 ) {
                     Row(
                         modifier = Modifier
                             .width(420.px)
                             .margin(right = 20.px),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Box(
                             modifier = Modifier
                                 .width(60.px)
                                 .height(60.px)
                                 .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                                 .borderRadius(100.px)
                                 .margin(right = 10.px),
                             contentAlignment = Alignment.Center
                         ) {
                             Image(src = Res.Images.footballicon)
                         }
                         Column {
                             Row(
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 SpanText(
                                     "Total Sessions",
                                     modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                         .fontSize(15.px)
                                 )
                                 Box(
                                     modifier = Modifier
                                         .width(14.px)
                                         .height(14.px)
                                         .backgroundColor(Colors.LightGray)
                                         .borderRadius(100.px)
                                         .margin(leftRight = 10.px),
                                     contentAlignment = Alignment.Center
                                 ) {
                                     FaInfo(
                                         modifier = Modifier.color(Colors.White)
                                             .cursor(Cursor.Pointer), size = IconSize.XXS
                                     )
                                 }
                             } to
                                     SpanText(
                                         "2",
                                         modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                             .fontWeight(
                                                 FontWeight.Bold
                                             ).margin(top = 6.px)
                                     )
                         }
                     }
                     Row(
                         modifier = Modifier
                             .width(420.px)
                             .margin(right = 20.px),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Box(
                             modifier = Modifier
                                 .width(60.px)
                                 .height(60.px)
                                 .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                                 .borderRadius(100.px)
                                 .margin(right = 10.px),
                             contentAlignment = Alignment.Center
                         ) {
                             FaClock(
                                 modifier = Modifier.color(sitePalette.primary.rgb),
                                 size = IconSize.XL,
                                 style = IconStyle.FILLED
                             )
                         }
                         Column {
                             Row(
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 SpanText(
                                     "Minutes Recorded",
                                     modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                         .fontSize(15.px)
                                 )
                                 Box(
                                     modifier = Modifier
                                         .width(14.px)
                                         .height(14.px)
                                         .backgroundColor(Colors.LightGray)
                                         .borderRadius(100.px)
                                         .margin(leftRight = 10.px),
                                     contentAlignment = Alignment.Center
                                 ) {
                                     FaInfo(
                                         modifier = Modifier.color(Colors.White)
                                             .cursor(Cursor.Pointer), size = IconSize.XXS
                                     )
                                 }
                             } to
                                     SpanText(
                                         "110:32",
                                         modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                             .fontWeight(
                                                 FontWeight.Bold
                                             ).margin(top = 6.px)
                                     )
                         }
                     }
                     Row(
                         modifier = Modifier
                             .width(420.px)
                             .margin(right = 20.px),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Box(
                             modifier = Modifier
                                 .width(60.px)
                                 .height(60.px)
                                 .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                                 .borderRadius(100.px)
                                 .margin(right = 10.px),
                             contentAlignment = Alignment.Center
                         ) {
                             Image(src = Res.Images.keywordIcon)
                         }
                         Column {
                             Row(
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 SpanText(
                                     "No. of Keywords",
                                     modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                         .fontSize(15.px)
                                 )
                                 Box(
                                     modifier = Modifier
                                         .width(14.px)
                                         .height(14.px)
                                         .backgroundColor(Colors.LightGray)
                                         .borderRadius(100.px)
                                         .margin(leftRight = 10.px),
                                     contentAlignment = Alignment.Center
                                 ) {
                                     FaInfo(
                                         modifier = Modifier.color(Colors.White)
                                             .cursor(Cursor.Pointer), size = IconSize.XXS
                                     )
                                 }
                             } to
                                     SpanText(
                                         "35",
                                         modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                             .fontWeight(
                                                 FontWeight.Bold
                                             ).margin(top = 6.px)
                                     )
                         }
                     }

                 }

             }
         }
     }
    }
}
package org.example.zone360.pages.dashboard.coaches

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.example.zone360.components.CommonButton
import org.example.zone360.components.formatSessionMinutes
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubCoachesData
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Divider
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CoachesLayout(
    data: List<GetClubCoachesData>,
    breakpoint: Breakpoint,
    onRecommendationClick: (GetClubCoachesData) -> Unit,
    addNewCoach: () -> Unit,
) {
    val sitePalette = ColorMode.current.toSitePalette()
    var selectedCoach by remember { mutableStateOf<GetClubCoachesData?>(null) }
    val sortedData by remember { mutableStateOf(data) }
    var isSortedDescending by remember { mutableStateOf(true) }
    var isHovered by remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .then(
                if ( window.screen.width > 765) Modifier.height(915.px)
                else Modifier.maxHeight(915.px)
            )
            .width(if (breakpoint > Breakpoint.LG) 68.percent else if(breakpoint == Breakpoint.LG) 59.percent
            else if(breakpoint >= Breakpoint.MD)54.percent else 1360.px)
            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
            .borderRadius(15.px)
            .overflow(
                overflowY = Overflow.Hidden,
                overflowX =   Overflow.Auto
            )
            .padding(
                leftRight = if (breakpoint >= Breakpoint.LG) 30.px else 20.px,
                topBottom = if (breakpoint >= Breakpoint.LG) 20.px else 10.px
            )
    ) {
        Column(
            modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .fillMaxHeight()
        ) {
            Row (modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween){
            SectionTitle("Coaches")
                CommonButton(
                    text = "Add New Coach",
                    icon = { FaPlus(size = IconSize.LG) },
                    width = 150.px,
                    height = 40.px,
                    onCLick = {
                        addNewCoach()
                    })
            }

            Row(
                modifier = Modifier.fillMaxWidth().height(50.px)
                    .backgroundColor(sitePalette.coachesTileRow.rgb),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Row(
                    modifier = Modifier.fillMaxSize(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                FieldTitleTextNormalSimple(
                    text = "Name",
                    width = 130.px,
                    fontWeight = 500,
                    fontSize = 15.px
                )
                    FieldTitleTextNormalSimple(
                            text = "Sessions",
                            width = 50.px,
                            fontWeight = 500,
                            fontSize = 13.px,
                            isClickable = true,
                        )

                    FieldTitleTextNormalSimple(
                        text = "Minutes",
                        width = 50.px,
                        fontWeight = 500,
                        fontSize = 13.px
                    )
                    FieldTitleTextNormalSimple(
                        text = "Speaking",
                        width = 50.px,
                        fontWeight = 500,
                        fontSize = 13.px
                    )
                    Row(
                        modifier = Modifier.width(60.px)
                            .onMouseEnter { isHovered = true }
                            .onMouseLeave { isHovered = false }
                            .onClick {
//                                println("Sort Icon Clicked!")
//                                isSortedDescending = !isSortedDescending
//                                sortedData = if (isSortedDescending) {
//                                    data.sortedByDescending { it.count }
//                                } else {
//                                    data.sortedBy { it.count }
//                                }
                            },
                        verticalAlignment = Alignment.CenterVertically
                    ) {
//                        if (isHovered) {
//                            if (isSortedDescending) {
//                                FaSortDown(
//                                    modifier = Modifier
//                                        .color(sitePalette.primary.rgb)
//                                        .margin(right = 5.px)
//                                        .cursor(Cursor.Pointer)
//                                )
//                            } else {
//                                FaSortUp(
//                                    modifier = Modifier
//                                        .color(sitePalette.primary.rgb)
//                                        .margin(right = 5.px)
//                                        .cursor(Cursor.Pointer)
//                                )
//                            }
//                        } else {
//                            CustomSpacer(16.px)
//                        }
                        FieldTitleTextNormalSimple(
                            text = "Keywords",
                            width = 50.px,
                            fontWeight = 500,
                            fontSize = 13.px,
                            isClickable = false,
                        )
                    }
//                    FieldTitleTextNormalSimple(
//                        text = "Efficiency",
//                        width = 50.px,
//                        fontWeight = 500,
//                        fontSize = 13.px
//                    )

                    FieldTitleTextNormalSimple(
                        text = "Emphasis",
                        width = 50.px,
                        fontWeight = 500,
                        fontSize = 13.px
                    )
                    FieldTitleTextNormalSimple(
                        text = "Recommendations",
                        width = 120.px,
                        fontWeight = 500,
                        fontSize = 13.px
                    )

                }
            }
            HorizontalDivider(modifier = Modifier.fillMaxWidth().margin(0.px).height((0.5).px).backgroundColor(sitePalette.textFieldBorderColor.rgb))
            Column(
                modifier = Modifier.fillMaxWidth()
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
            ) {
                if (sortedData.isNotEmpty()) {
                    CoachesData(
                        data = sortedData,
                        onRecommendationClick = { coach ->
                            selectedCoach = coach
                            onRecommendationClick(coach)
                        }
                    )
                }
            }
        }
    }
}

@Composable
fun CoachesData(
    data: List<GetClubCoachesData>,
    onRecommendationClick: (GetClubCoachesData) -> Unit = {}
) {
    val ctx = rememberPageContext()
    val sitePalette = ColorMode.current.toSitePalette()
    val sortedCoaches = data.sortedWith(
        compareByDescending<GetClubCoachesData> { it.efficiency }
            .thenByDescending { it.count }
    )

    //
    sortedCoaches.forEach { coach ->
        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(topBottom = 10.px)
                .onClick { ctx.router.navigateTo("/coach-details") },
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween

        ) {
            Row(
                modifier = Modifier.width(130.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (coach.imageUrl.isNotEmpty()) {
                    Image(
                        src = "${Res.Images.coachImageUrl}${coach.imageUrl}",
                        modifier = Modifier.size(30.px).margin(right = 5.px)
                            .borderRadius(100.px).textAlign(TextAlign.Start)
                    )
                } else {
                    Image(
                        src = Res.Images.userIcon,
                        modifier = Modifier.size(30.px).margin(right = 7.px)
                            .borderRadius(100.px)
                    )
                }
                FieldTitleTextNormalSimple(
                    isClickable = true,
                    text = "${coach.firstName} ${coach.lastName}",
                    fontSize = 13.px,
                )
            }
            FieldTitleTextNormalSimple(
                text = if(coach.count>0) coach.count.toString() else "-",
                width = 50.px,
                fontSize = 13.px,
                textAlign = TextAlign.Center
            )
            FieldTitleTextNormalSimple(
                text = if(coach.count>0)(coach.recordTime/1000).formatSessionMinutes() else "-",
                width = 50.px,
                fontSize = 13.px,
                textAlign = TextAlign.Center
            )
            FieldTitleTextNormalSimple(
                text = if(coach.count>0) "${
                    if (coach.recordTime == 0) 0 else (((coach.speakingTime*1000) / coach.recordTime)*100).toInt()
                } %" else "-",
                width = 60.px,
                fontSize = 13.px,
                textAlign = TextAlign.Center
            )
            FieldTitleTextNormalSimple(
                text = if(coach.count>0) coach.totalKeywords.toString() else "-", width = 60.px,
                fontSize = 13.px,
                textAlign = TextAlign.Center
            )
//            FieldTitleTextNormalSimple(
//                text = if (coach.count > 0) (coach.efficiency.asDynamic().toFixed(1) as String) else "-",
//                width = 50.px,
//                fontSize = 13.px,
//                textAlign = TextAlign.Center
//            )
            FieldTitleTextNormalSimple(
                text = coach.emphais.ifEmpty { "-" }.replaceFirstChar {
               if (it.isLowerCase()) it.titlecase() else it.toString()
                },
                fontSize = 13.px,
                width = 50.px,
                textAlign = TextAlign.Center
            )
            Box(modifier = Modifier.width(120.px),
                contentAlignment = Alignment.Center) {
            CommonButton(
                text = "View",
                textSpanColor = if (coach.count > 0) sitePalette.viewText.rgb else sitePalette.commonButtonSupportiveTextColor.rgb,
                backgroundColor = if (coach.count > 0) sitePalette.viewButtonBackground.rgb else sitePalette.textFieldBorderColorDisabled.rgb,
                width = 64.px,
                height = 28.px,
                pointerCursor = coach.count > 0,
                onCLick = {
                    if (coach.count > 0) {
                        onRecommendationClick(coach)
                        println("coachButton Clicked" + coach.firstName)
                    }
                },
            )}

        }
        Divider()
    }

}
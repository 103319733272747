package org.example.zone360.pages.dashboard.playerAssessment

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.example.zone360.components.CommonButton
import org.example.zone360.components.SearchBar
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubCoachesData
import org.example.zone360.models.GetPlayerData
import org.example.zone360.models.GetPlayerResponse
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Divider
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Col

@Composable
fun PlayerAssessment(
    data: List<GetPlayerData>,
    breakpoint: Breakpoint,
    onRecommendationClick: (GetPlayerData) -> Unit,
    addNewPlayer: () -> Unit,
    deletePlayer: () -> Unit
){
    val sitePalette = ColorMode.current.toSitePalette()
    var searchText by remember { mutableStateOf("") }
    val filteredData = remember(searchText, data) {
        if (searchText.isEmpty()) data
        else data.filter { player ->
            val playerName = "${ player.firstName} ${ player.lastName}"
            playerName.contains(searchText, ignoreCase = true)
        }
    }

    Box (modifier = Modifier
        .width(if (breakpoint > Breakpoint.LG) 68.percent else if(breakpoint == Breakpoint.LG) 59.percent
        else if(breakpoint >= Breakpoint.MD)54.percent else 1360.px)
        .height(700.px)
        .overflow(
            overflowY = Overflow.Hidden,
            overflowX =   Overflow.Auto
        )
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .borderRadius(15.px)
        .padding(topBottom = 10.px)
    ) {
        Column(
            modifier = Modifier.width(if (window.screen.width < 1500) 1360.px else 100.percent)
                .fillMaxHeight(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier.padding(leftRight = 25.px, topBottom = 15.px).fillMaxWidth()
                    .flexWrap(FlexWrap.Wrap),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Row(
                    modifier = Modifier.flexWrap(FlexWrap.Wrap),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    SectionTitle("Player Assessment", bottom = 0.px)
                    SearchBar(
                        placeholder = "Search player...",
                        id = "searchbar",
                        breakpoint = Breakpoint.LG,
                        modifier = Modifier.fillMaxWidth().margin(left = 20.px),
                        onEnterClick = {},
                        onChange = { newSearchText ->
                            searchText = newSearchText
                        },
                        onClick = {},
                        width = 250.px,
                        showClearButton = false
                    )
                }
                Row {
                    CommonButton(
                        text = "Add New Player",
                        icon = { FaPlus(size = IconSize.LG) },
                        width = 150.px,
                        height = 40.px,
                        onCLick = {
                            addNewPlayer()
                        })
                }
            }
            Column(modifier = Modifier.fillMaxWidth().padding(leftRight = 30.px)) {
                Row(
                    modifier = Modifier.fillMaxWidth().height(50.px)
                        .backgroundColor(sitePalette.coachesTileRow.rgb),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth(99.percent),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        FieldTitleTextNormalSimple(
                            text = "Name",
                            width = 130.px,
                            fontWeight = 500,
                            fontSize = 15.px
                        )
                        FieldTitleTextNormalSimple(
                            text = "Assessments",
                            width = 50.px,
                            fontWeight = 500,
                            isClickable = true,
                        )

                        FieldTitleTextNormalSimple(
                            text = "Coach",
                            width = 120.px,
                            fontWeight = 500,
                            textAlign = TextAlign.Center,

                            )

                        FieldTitleTextNormalSimple(
                            text = "Email",
                            width = 310.px,
                            fontWeight = 500,
                            textAlign = TextAlign.Center,
                            )
                        FieldTitleTextNormalSimple(
                        text = "Recommendations",
                        width = 100.px,
                        fontWeight = 500,
                        isClickable = false,
                        textAlign = TextAlign.Center
                        )
//                        FieldTitleTextNormalSimple(
//                            text = "Action",
//                            width = 50.px,
//                            fontWeight = 500,
//                            isClickable = true,
//                        )

                    }
                }
                HorizontalDivider(
                    modifier = Modifier.fillMaxWidth().margin(0.px).height((0.5).px)
                        .backgroundColor(sitePalette.textFieldBorderColor.rgb)
                )
                Column(
                    modifier = Modifier.fillMaxWidth().maxHeight(540.px)
                        .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                ) {
                    if (filteredData.isNotEmpty()) {
                        PlayersData(
                            data = filteredData,
                            onRecommendationClick = onRecommendationClick,
                            deletePlayer = deletePlayer
                        )
                    }
                }
            }
        }
    }
    }

@Composable
fun PlayersData(
    data: List<GetPlayerData>,
    onRecommendationClick: (GetPlayerData) -> Unit = {},
    deletePlayer: () -> Unit
){
    val sitePalette = ColorMode.current.toSitePalette()
    val sortedPlayer = data.sortedWith(
        compareByDescending { it.count }
    )
    sortedPlayer.forEach {  player ->
    Row(
        modifier = Modifier.fillMaxWidth()
            .margin(topBottom = 10.px),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Row(
            modifier = Modifier.width(130.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if ( player.imageUrl.isNotEmpty()) {
                Image(
                    src = "${Res.Images.coachImageUrl}${ player.imageUrl}",
                    modifier = Modifier.size(30.px).margin(right = 5.px)
                        .borderRadius(100.px).textAlign(TextAlign.Start)
                )
            } else {
                Image(
                    src = Res.Images.userIcon,
                    modifier = Modifier.size(30.px).margin(right = 7.px)
                        .borderRadius(100.px)
                )
            }
            FieldTitleTextNormalSimple(
                text ="${ player.firstName} ${ player.lastName}".ifEmpty { "-" }.replaceFirstChar {
                    if (it.isLowerCase()) it.titlecase() else it.toString()
                },
                )
        }
        FieldTitleTextNormalSimple(
            text = player.count.toString(),
            width = 50.px,
            textAlign = TextAlign.Center
        )
        FieldTitleTextNormalSimple(
            text =player.coachName.ifEmpty { "-" }.replaceFirstChar {
                if (it.isLowerCase()) it.titlecase() else it.toString()
            },
            width = 120.px,
            textAlign = TextAlign.Center
        )
            Box (modifier = Modifier.width(310.px),
                contentAlignment = Alignment.Center){
                Box(
                    modifier = Modifier.maxWidth(305.px),
                    contentAlignment = Alignment.Center
                ) {
                    Box(
                        modifier = Modifier
//                            .padding(topBottom = 5.px)
                            .borderRadius(4.px)
                            .padding(10.px)
                            .backgroundColor(sitePalette.viewButtonBackground.rgb)
                            .onClick {
                            },
                        contentAlignment = Alignment.Center
                    ) {
                        FieldTitleTextNormalSimple(
                            text = player.email,
                            textAlign = TextAlign.Center,
                            color = sitePalette.viewText.rgb
                        )
                    }
                }
            }
        Box (modifier = Modifier.width(100.px),
            contentAlignment = Alignment.Center){
            CommonButton(
                text = "View",
                textSpanColor = if (player.count > 0) sitePalette.viewText.rgb else sitePalette.commonButtonSupportiveTextColor.rgb,
                backgroundColor = if (player.count > 0) sitePalette.viewButtonBackground.rgb else sitePalette.textFieldBorderColorDisabled.rgb,
                pointerCursor = player.count > 0,
                width = 64.px,
                height = 28.px,
                onCLick = {
                    if (player.count > 0) {
                        onRecommendationClick(player)
                    }
                }
            )}
//        Box (modifier = Modifier.width(50.px),
//            contentAlignment = Alignment.Center){
//            FaTrash(modifier = Modifier.onClick {
//                deletePlayer()
//            }.cursor(Cursor.Pointer))
//        }
    }
    Divider()
    }
}
@file:Suppress("DEPRECATION")

package org.example.zone360.style

import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.textOverflow
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import org.example.zone360.theme.Palette
import org.example.zone360.utils.Constants


val BaseTextStyle by ComponentStyle {
    base {
        Modifier
            .fillMaxWidth()
            .color(Palette.Primary.rgbLight)
            .fontFamily(Constants.FONT_FAMILY)
            .overflow(Overflow.Hidden)
            .textOverflow(TextOverflow.Ellipsis)
            .styleModifier {
                property("display", "-webkit-box")
                property("-webkit-line-clamp", "2")
                property("line-clamp", "2")
                property("-webkit-box-orient", "vertical")
            }
    }
}

